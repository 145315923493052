import {
  // gql,
  InMemoryCache,
  // Reference,
  makeVar,
} from "@apollo/client";
import { add } from "date-fns";

//global variables to be used everywhere in the app

export const isLoggedInVar = makeVar(!!sessionStorage.getItem("jwt"));

export const CognitoUserVar = makeVar();

export const filterListVar = makeVar(
  JSON.parse(localStorage.getItem("filterList"))
    ? JSON.parse(localStorage.getItem("filterList"))
    : {},
);

export const creatingScheduleVar = makeVar(false);

export const creatingScheduleTrainingVar = makeVar(false);

export const userVar = makeVar({});

export const notificationsVar = makeVar({});

export const locationConfigVar = makeVar({});

export const officeConfigVar = makeVar({});

export const selectedDateVar = makeVar(new Date());

export const schedulerEventsVar = makeVar({
  formatted: [],
  userShifts: [],
  shiftNames: [],
});

export const selectedEventVar = makeVar(null);

export const slackAssignmentVar = makeVar({ shiftId: "" });

export const initialScheduleStartDateVar = makeVar(null);

export const newScheduleStartDateVar = makeVar(false);

export const appsettingsVar = makeVar({ logo: null, color: "" });

export const EditDepartmentVar = makeVar({ isEmpty: false });

export const DepartmentJobTypeVar = makeVar({ isEmpty: true });

export const DepartmentTrainingVar = makeVar({ isEmpty: true });

export const SelectedOfficeVar = makeVar({ officeId: "" });

export const OpenRefreshTokenDialogVar = makeVar(false);

export const SaveEventstoloadonRefesh = makeVar({});

export const ManualEventsToSave = makeVar([]);

export const ShiftDefinitionsVar = makeVar([]);

export const getOfficeScheduleStatus = makeVar({ officeId: "" }); //global variable to check for Office schedule status after updating slacks from float office

export const setShiftIDToEdit = makeVar({}); //global variable to set shift ID to edit after options are retrieved from updating slacks as a float manager

export const schedulePeriodToUpdateAfterUpdateSlacks = makeVar({
  schedulePeriodId: "",
});

export const eventsDatesVar = makeVar([]);

export const forceOptionsExecution = makeVar({
  started: false,
});

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
      },
    },
  },
});
